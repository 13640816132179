<template>
  <div>
    <div class="mnewsListDetail_title">
      <van-icon name="arrow-left" @click="toDetails" />
      <div>{{ info.title }}</div>
    </div>
    <div class="mnewsListDetail_content">
      <div class="mnewsListDetail_content_detail">
        <div
          class="mnewsListDetail_content_detail_img"
          v-html="info.formatContent"
        ></div>
      </div>
    </div>
    <div class="news_recommend" v-if="recommendList.length > 0">
      <div class="news_recommend_bg">
        <div class="news_recommend_name">推荐文章</div>
        <div class="news_recommend_title">
          {{ recommendList[0].title }}
        </div>
        <div class="news_recommend_scroll">
          <div v-show="recommendPage != 0" @click="otherPage(-1)">上一篇</div>
          <div
            v-show="recommendPage + 1 != recommendTotal"
            @click="otherPage(1)"
          >
            下一篇
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "mnewsListDetail",
  data() {
    return {
      info: {},
      businessList: [],
      recommendList: [],
      recommendPage: 0,
      recommendTotal: 0,
    };
  },
  mounted() {
    window.scroll(0, 0);
    const id = this.$route.query.id;
    this.getDetail(id);
    this.getList();
  },
  methods: {
    getDetail(id) {
      //获取独立的数据
      axios
        .get(`https://admin.zoneyu.cn/api/admin/posts/${id}`, {
          headers: {
            "Admin-Authorization": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.info = res.data.data;
          const updateTime = new Date(this.info.createTime);
          this.info.day =
            updateTime.getDate() > 9
              ? updateTime.getDate()
              : "0" + updateTime.getDate();
          this.info.year =
            updateTime.getFullYear() +
            "." +
            (updateTime.getMonth() + 1 > 9
              ? updateTime.getMonth() + 1
              : "0" + (updateTime.getMonth() + 1));
          if (this.info.formatContent.indexOf("https") > -1) {
          } else {
            this.info.formatContent = this.info.formatContent.replaceAll(
              "/upload/",
              "https://admin.zoneyu.cn/upload/"
            );
          }
        });
    },
    toDetails() {
      this.$router.go(-1);
    },
    otherPage(val) {
      this.recommendPage = this.recommendPage + val;
      this.getList();
    },
    getList() {
      const page = this.recommendPage
      axios
        .get(
          `https://admin.zoneyu.cn/api/admin/posts?status=PUBLISHED&page=${page}&size=1&categoryId=18`,
          {
            headers: {
              "Admin-Authorization": localStorage.getItem("token"),
            },
          }
        )
        .then(async (res) => {
          const content = res.data.data.content;
          content.forEach((item) => {
            const updateTime = new Date(item.createTime);
            item.day =
              updateTime.getDate() > 9
                ? updateTime.getDate()
                : "0" + updateTime.getDate();
            item.year =
              updateTime.getFullYear() +
              "." +
              (updateTime.getMonth() + 1 > 9
                ? updateTime.getMonth() + 1
                : "0" + (updateTime.getMonth() + 1));
            item.title =
              item.title.length > 30
                ? item.title.substring(0, 30) + "..."
                : item.title;
          });
          this.recommendList = content;
          this.recommendTotal = res.data.data.total;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.mnewsListDetail_title {
  width: calc(100% - 24px);
  padding: 0 12px;
  font-size: 12px;
  font-weight: 400;
  color: #565759;
  line-height: 33px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.mnewsListDetail_content {
  width: calc(100% - 24px);
  padding: 0 12px;
  display: flex;
  .mnewsListDetail_content_detail {
    width: 100%;
    .mnewsListDetail_content_detail_head {
      display: flex;
    }
    /deep/ .mnewsListDetail_content_detail_img {
      img {
        width: 100%;
      }
    }
  }
}
.news_recommend {
  padding: 30px 12px;
  .news_recommend_bg {
    background-color: #f7f7f7;
    padding: 12px;
    .news_recommend_name {
      font-size: 14px;
      font-weight: 600;
      color: #1d1b19;
      line-height: 25px;
      letter-spacing: 1px;
    }
    .news_recommend_title {
      font-size: 14px;
      color: #565759;
      line-height: 25px;
      letter-spacing: 1px;
      margin-top: 4px;
      padding-bottom: 12px;
      border-bottom: 1px dashed #e5e5e5;
      white-space: nowrap; /* 防止文本换行 */
      overflow: hidden; /* 控制文本溢出时隐藏超出部分 */
      text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
    }
    .news_recommend_scroll {
      margin-top: 12px;
      font-size: 12px;
      font-weight: 300;
      color: #9e1307;
      line-height: 25px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
